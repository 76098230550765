<template>
  <div class="softwareComponentTechnicalAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Technical Infospace
        </h4>
        <hr class="m-0 mb-3" />
        <label>URL</label>
        <input
          ref="url"
          v-model="technicalInfospace.url"
          v-focus
          required
          pattern="https://.*|http://.*"
          :class="[
            'url form-control',
            { 'is-invalid': $validator.errors.has('Url') },
          ]"
          type="url"
        />
        <hr class="m-0 mb-3" />
        <label>{{ $t("description") }}</label>
        <textarea
          ref="confTextArea"
          v-model="technicalInfospace.description"
          class="form-control"
          type="text"
          rows="3"
        />
        <hr class="m-0 mb-3" />
        <button
          class="btn btn-primary float-right"
          :disabled="!isUrlValid || !technicalInfospace.url"
          @click.prevent="save()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t("save") }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>
import { errorMixin } from "@/mixins/errorMixin.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SoftwareComponentTechnicalAdd",
  mixins: [errorMixin],
  data() {
    return {
      technicalInfospace: {
        id: uuidv4(),
      },
      isUrlValid: false,
    };
  },
  watch: {
    "technicalInfospace.url"() {
      if (this.$refs["url"]) {
        this.isUrlValid = this.$refs["url"].validity.valid;
      }
    },
  },
  methods: {
    save() {
      this.$emit('saveTechnical', this.technicalInfospace)
    }
  },
};
</script>
